import "../stylesheets/SubmissionForm.css";
import config from "../config.js";

function SubmissionForm({
  question,
  handleQuestionChange,
  isInputEmpty,
  setIsLoading,
  setErrorRequest,
  setGPTDeclinedAnswer,
  setAnswer,
  setCitations,
}) {
  const queryBackend = async (question) => {
    try {
      // Create a promise for the fetch operation
      const fetchPromise = fetch(config.API_ENDPOINT + '/ask', {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ question: question, nr_hits: config.NR_HITS }),
      });

      // Create a timeout promise
      const timeoutPromise = new Promise((_, reject) =>
        setTimeout(
          () => reject(new Error("Request timed out")),
          config.FETCH_TIMEOUT
        )
      );

      // Race the fetch against the timeout
      const response = await Promise.race([fetchPromise, timeoutPromise]);
      const responseData = await response.json();

      if (!response.ok) {
        // This condition is triggered when backend API responded with a failure response code.

        setErrorRequest("Something went wrong with the backend API");

        if (response.status === 500) {
          // This section handles error message explicitly triggered by us from backend
          const errorMessage = responseData.error || "Error in Parsing JSON";
          // Update error message
          setErrorRequest(errorMessage);

          if (errorMessage === "Can not answer based on facts") {
            setGPTDeclinedAnswer(true); // GPT did not find facts in the passages and declined answering.
          }
        } else {
        }
      }
      return responseData;
    } catch (error) {
      // This condition is triggered when: (1) backend API fails (non-responsive), (2) time-out occurs.
      setErrorRequest(error.message);
      console.error("There was a problem with your fetch operation:", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Reset variables
    setErrorRequest("");
    setAnswer("");
    setCitations("");
    setGPTDeclinedAnswer(false);

    // Start loading
    setIsLoading(true);
    const data_backend = await queryBackend(question);

    if (data_backend) {
      setAnswer(data_backend.answer);
      setCitations(Object.values(data_backend.top_passages));
    }

    setIsLoading(false); // Stop loading
  };

  return (
    <div className="form-group">
      <form onSubmit={handleSubmit} className="input-form">
        <div className="input-wrapper">
          <input
            type="text"
            value={question}
            onChange={handleQuestionChange}
            placeholder="Enter your question here..."
            className="question-input"
          />
          <i
            className={`fas fa-arrow-circle-right submit-icon ${
              isInputEmpty ? "icon-dark" : "icon-light"
            }`}
            onClick={handleSubmit}
          ></i>
        </div>
      </form>
    </div>
  );
}

export default SubmissionForm;
