import React, { useState } from "react";
import "./stylesheets/App.css";
import SubmissionForm from "./components/SubmissionForm";
import CitationsHolder from "./components/CitationsHolder";

function App() {
  const [question, setQuestion] = useState("");
  const [isInputEmpty, setIsInputEmpty] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [errorRequest, setErrorRequest] = useState("");
  // Tracks if GPT has declined answering to our question based on lack of facts.
  const [GPTDeclinedAnswer, setGPTDeclinedAnswer] = useState(false); 
  
  const [answer, setAnswer] = useState("");
  const [citations, setCitations] = useState([]);

  const handleQuestionChange = (event) => {
    setQuestion(event.target.value);
    setIsInputEmpty(event.target.value === "");
  };

  return (
    <div className="App">
      <div className="heading">
        <img
          src={`${process.env.PUBLIC_URL}/logo.png`}
          alt="Logo"
          className="logo-image"
        />
        <h1>WikiBot</h1>
      </div>

      <div className="main-container">
        <SubmissionForm
          question={question}
          handleQuestionChange={handleQuestionChange}
          isInputEmpty={isInputEmpty}
          setIsLoading={setIsLoading}
          setErrorRequest={setErrorRequest}
          setGPTDeclinedAnswer={setGPTDeclinedAnswer}
          setAnswer={setAnswer}
          setCitations={setCitations}
        />

        {(errorRequest !== "" && GPTDeclinedAnswer === false) && 
          // Do not display error message if GPT model explicility declined to answer
          <>
          <div className="notification"> Something went wrong while processing your request, please try again after some time. </div>
          <p> {errorRequest} </p>
          </>
        }

        {isLoading && <div className="notification">Loading...</div>}

        {answer !== "" && (
          <>
            <div className="answer">{answer}</div>
            
            {GPTDeclinedAnswer === false && 
            <CitationsHolder citations={citations} />
            }
          </>
        )}


      </div>
    </div>
  );
}

export default App;
