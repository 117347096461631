import React, { useState } from "react";
import "../stylesheets/CitationsHolder.css";

// Function to get favicon URL
const getFaviconUrl = (url) => {
  const urlObj = new URL(url);
  return `https://s2.googleusercontent.com/s2/favicons?domain=${urlObj.hostname}`;
};

// CitationsHolder Component
function CitationsHolder({ citations }) {
  const [expandedCitation, setExpandedCitation] = useState(null); // Track the expanded citation

  // Toggle the expanded state for a citation
  const toggleExpand = (index) => {
    setExpandedCitation(expandedCitation === index ? null : index);
  };

  return (
    <div>
      <h3 style={{ textAlign: "left" }}> Sources: </h3>
      <ol className="citations-holder">
        {citations.map((citation, index) => (
          <li key={index} className="citation">
            <img src={getFaviconUrl(citation.url)} alt="Favicon" className="favicon" />
            <a href={citation.url} target="_blank" rel="noopener noreferrer">
              {citation.title}
            </a>
            <i
              className={`expand-icon fas ${expandedCitation === index ? 'fa-compress' : 'fa-solid fa-plus'}`}
              onClick={() => toggleExpand(index)}
            ></i>
            {expandedCitation === index && (
              <div className="passage-box">
                {citation.passage}
              </div>
            )}
          </li>
        ))}
      </ol>
    </div>
  );
}

export default CitationsHolder;
